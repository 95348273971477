<template>
  <div class="top_bar">
    <div class="top_bar_cont w1200 between">
      <div></div>
      <ul class="nav_wrap">
        <li
          class="nav_item"
          @click="toSysArticle(1)"
        >
          关于我们
        </li>
        <li class="nav_item">
          <a
            href="https://www.wmkjfw.cn/web/signup"
            target="_blank"
            >会员注册</a
          >
        </li>
        <li class="nav_item">
          <a
            href="https://www.wmkjfw.cn/web/signup"
            target="_blank"
            >网点注册</a
          >
        </li>
        <li class="nav_item">
          <a
            href="https://www.wmkjfw.cn/web/login"
            target="_blank"
            >网点登录</a
          >
        </li>
        <li class="nav_item">
          <a
            href="https://www.wmkjfw.cn/web/signup/source"
            target="_blank"
            >源头注册</a
          >
        </li>
        <li class="nav_item">
          <a
            href="https://www.wmkjfw.cn/web/login"
            target="_blank"
            >源头登录</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
const toSysArticle = (id) => {
  router.push({
    path: "/sysArticle",
    query: {
      id,
    },
  });
};
</script>

<style lang="scss" scoped>
.top_bar {
  width: 100%;
  height: 35px;
  background-color: #fff;
  // position: fixed;
  // left: 0;
  // top: 0;
  z-index: 100;
  .nav_wrap {
    display: flex;
    align-items: center;
    height: 100%;
    .nav_item {
      height: 100%;
      line-height: 35px;
      font-size: 12px;
      color: #999;
      font-weight: 700;
      cursor: pointer;
      padding: 0 12px;
      box-sizing: border-box;
      position: relative;
      &::before {
        content: "";
        width: 1px;
        height: 14px;
        background-color: #ccc;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
      &:hover {
        color: #e4393c;
      }
    }
  }
}
</style>
