<template>
  <footer class="page_footer">
    <div class="w1200">
      <div class="service_line">
        <span
          class="service_txt"
          v-for="(item, index) in service_line1"
          :key="index"
          @click="toSysArticle(item.value)"
          >{{ item.label }}</span
        >
      </div>
      <div class="service_line service_line_primary">
        <span
          class="service_txt"
          v-for="(item, index) in service_line2"
          :key="index"
          @click="toSysArticle(item.value)"
          >{{ item.label }}</span
        >
      </div>
    </div>
    <div class="copyright_wrap">
      <p>
        Copyright © 2022. 闻名科技 All Rights reserved.
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/"
          >豫ICP备2022018525号</a
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41100202000769"
          >豫公网安备 41100202000975号</a
        >
        <a
          target="_blank"
          href="https://www.wmkjfw.cn/assets/imgs/edi.jpg"
          >电信增值业务EDI:豫B2-20221437</a
        >
      </p>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";

const service_line1 = ref([
  { label: "购物指南", value: 2 },
  { label: "服务保障", value: 3 },
  { label: "支付方式", value: 4 },
  { label: "配送方式", value: 5 },
  { label: "售后服务", value: 6 },
  { label: "查看更多", value: 7 },
]);
const service_line2 = ref([
  { label: "公益报道", value: 8 },
  { label: "品牌招商", value: 9 },
  { label: "平台规划", value: 10 },
  { label: "隐私条款", value: 11 },
  { label: "服务条款", value: 12 },
  { label: "闻名诚聘", value: 13 },
]);

const toSysArticle = (id) => {
  router.push({
    path: "/sysArticle",
    query: {
      id,
    },
  });
};

const onEdiClick = () => {};
</script>

<style lang="scss" scoped>
.page_footer {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  .service_line {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    .service_txt {
      flex: 1;
      color: #303133;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
    }
  }
  .service_line_primary {
    background-color: #e4393c;
    .service_txt {
      color: #fff;
    }
  }
  .copyright_wrap {
    width: 100%;
    background-color: #f8f8f9;
    padding: 30px 0;
    p {
      text-align: center;
      line-height: 1.5;
      margin: 0;
      color: #80848f;
      font-size: 12px;
    }
    .edi {
      cursor: pointer;
    }
  }
}
</style>
