<template>
  <el-config-provider :locale="i18nLocale">
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import zhCn from "element-plus/es/locale/lang/zh-cn";

export default {
  data() {
    return {
      i18nLocale: zhCn,
    };
  },
};
</script>

<style scoped></style>
