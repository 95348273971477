import { createRouter, createWebHashHistory } from "vue-router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css";
import Index from "../views/index/index.vue";

NProgress.configure({ showSpinner: true });

const constantRoutes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/product/detail.vue"),
  },
  {
    path: "/article",
    name: "Article",
    component: () => import("@/views/article/index.vue"),
  },
  {
    path: "/plist",
    name: "ProductList",
    component: () => import("@/views/product/list.vue"),
  },
  {
    path: "/clist",
    name: "CompanyList",
    component: () => import("@/views/company/list.vue"),
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("@/views/company/detail.vue"),
  },
  {
    path: "/sysArticle",
    name: "sysArticle",
    component: () => import("@/views/sysArticle/index.vue"),
  },
  {
    path: "/store",
    name: "StoreList",
    component: () => import("@/views/store/list.vue"),
  },
  {
    path: "/storeDto",
    name: "StoreDto",
    component: () => import("@/views/store/detail.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
  scrollBehavior() {
    return { top: 0 };
  },
});

// router.beforeEach(async (to, from, next) => {
//   NProgress.start();
//   const userStore = useUserStore();
//   // 如果登录
//   const token = getItem("token");
//   const tokenExpired = getItem("tokenExpired");
//   if (token && tokenExpired > Date.now()) {
//     if (to.path === "/login") {
//       next("/");
//       NProgress.done();
//     } else {
//       if (userStore.role && userStore.role.length) {
//         next();
//         NProgress.done();
//       } else {
//         try {
//           const menuList = await userStore.getInfo();
//           console.log("menuList::", menuList);
//           const accessRoutes = [
//             ...menuList,
//             { path: "/:pathMatch(.*)", redirect: "/404", hidden: true },
//           ];
//           accessRoutes.forEach((r) => {
//             router.addRoute(r);
//           });
//           next({ ...to, replace: true });
//           NProgress.done();
//         } catch (error) {
//           userStore.resetInfo();
//           next({
//             path: "/login",
//             query: {
//               redirect: to.path,
//               ...to.query,
//             },
//           });
//           NProgress.done();
//         }
//       }
//     }
//   } else {
//     if (to.path === "/login") {
//       next();
//       NProgress.done();
//       return;
//     }
//     next({
//       path: "/login",
//       query: {
//         redirect: to.name,
//         ...to.query,
//       },
//     });
//     NProgress.done();
//   }
// });

export default router;
