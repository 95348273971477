<template>
  <div class="floor_block">
    <div class="floor_head between">
      <span class="floor_head_title">视频传播</span>
      <div class="floor_head_right">
        <div class="cate_list">
          <span
            class="cate_item"
            v-for="(cate, cateIdx) in categoryList"
            :key="cateIdx"
            :class="{ active: categoryId == cate.id }"
            @click="categoryChange(cate.id)"
            >{{ cate.name }}</span
          >
        </div>
        <el-dropdown @command="categoryChange">
          <div class="more_wrap">
            <span class="more_txt">查看更多</span>
            <div class="arrow_circle center">
              <el-icon
                color="#fff"
                size="12px"
                ><ArrowRightBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(cate, cateIdx) in categoryAllList"
                :key="cateIdx"
                :command="cate.id"
                >{{ cate.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <div
      class="floor_cont"
      v-loading="loading"
      v-if="!dataList.length"
    >
      <div class="empty_wrap center">
        <el-empty
          :image-size="120"
          description="暂无数据"
        />
      </div>
    </div>

    <div
      class="floor_cont"
      v-loading="loading"
      v-else
    >
      <div
        class="article_item"
        v-for="(item, index) in dataList"
        :key="index"
        @click="itemClick(item)"
      >
        <div class="cover_wrap">
          <img
            :src="domain + item.cover"
            class="article_cover"
            alt=""
          />
        </div>
        <div class="card_title">{{ item.title }}</div>
        <div class="card_date">{{ item.enterpriseName }}</div>
        <div class="card_date">{{ item.createdAt }}</div>
      </div>
    </div>

    <div class="floor-pager">
      <el-pagination
        v-if="pager.total > 4"
        background
        layout="prev, pager, next"
        :total="pager.total"
        v-model:page-size="pager.pageSize"
        v-model:current-page="pager.current"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import useAppStore from "@/store/modules/app";
import { queryVideoCategory, queryVideoArticle } from "@/api/home";
import useFloor from "@/hooks/useFloor";
const emit = defineEmits(["change", "detail"]);
const appStore = useAppStore();
const domain = computed(() => appStore.domain);

const {
  loading,
  pager,
  dataList,
  categoryId,
  categoryList,
  categoryAllList,
  loadData,
  onPageChange,
  categoryChange,
} = useFloor({
  pageSize: 4,
  categoryApi: queryVideoCategory,
  dataApi: queryVideoArticle,
});

const itemClick = (item) => {
  emit("detail", {
    item,
    type: "article",
  });
};
</script>
<style lang="scss" scoped>
// 58  244 32(47)
.floor_block {
  // padding-top: 35px;
  .floor_head {
    width: 100%;
    height: 58px;
    &_title {
      font-size: 22px;
      color: #303133;
    }
    &_right {
      display: flex;
      align-items: center;
      height: 58px;

      .cate_list {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 30px;
        .cate_item {
          color: #424242;
          font-size: 16px;
          margin-left: 25px;
          height: 30px;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #e4393c;
          }
          &:first-child {
            margin-left: 0;
          }
          &.active {
            color: #e4393c;
            &::before {
              content: "";
              width: 100%;
              height: 2px;
              background-color: #e4393c;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
      .more_wrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          .more_txt {
            color: #e4393c;
          }
          .arrow_circle {
            background-color: #e4393c;
          }
        }
        .more_txt {
          color: #909399;
          font-size: 16px;
        }
        .arrow_circle {
          width: 20px;
          height: 20px;
          background-color: #b0b0b0;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }
  }
  .floor_cont {
    height: 244px;
    display: flex;
    flex-flow: row wrap;
    .article_item {
      width: 289.5px;
      height: 230px;
      margin-right: 14px;
      margin-bottom: 14px;
      transition: transform 0.3s, -webkit-transform 0.3s;
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0 6px 14px 0 hsl(0deg 0% 7% / 6%);
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        transform: scale(1.04);
      }
      .cover_wrap {
        width: 100%;
        height: 130px;
        .article_cover {
          width: 100%;
          height: 100%;
        }
      }
      .card_title {
        padding: 12px;
        padding-bottom: 0;
        box-sizing: border-box;
        color: #121212;
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .card_date {
        padding: 6px 12px;
        box-sizing: border-box;
        color: #8590a6;
        font-size: 14px;
        text-align: right;
      }
    }
    .empty_wrap {
      width: 100%;
      background-color: #fff;
      :deep(.el-empty) {
        padding: 10px 0;
      }
    }
  }
  .floor-pager {
    height: 47px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
