<template>
  <div class="floor_block">
    <div class="floor_head between">
      <span class="floor_head_title">本区服务</span>
      <div class="floor_head_right">
        <div class="cate_list">
          <span
            class="cate_item"
            v-for="(cate, cateIdx) in categoryList"
            :key="cateIdx"
            :class="{ active: categoryId == cate.id }"
            @click="categoryChange(cate.id)"
            >{{ cate.name }}</span
          >
        </div>
        <el-dropdown @command="categoryChange">
          <div class="more_wrap">
            <span class="more_txt">查看更多</span>
            <div class="arrow_circle center">
              <el-icon
                color="#fff"
                size="12px"
                ><ArrowRightBold
              /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(cate, cateIdx) in categoryAllList"
                :key="cateIdx"
                :command="cate.id"
                >{{ cate.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <div
      class="floor_cont"
      v-if="!dataList.length"
      v-loading="loading"
    >
      <div class="empty_wrap center">
        <el-empty
          :image-size="120"
          description="暂无数据"
        />
      </div>
    </div>

    <div
      class="floor_cont"
      v-loading="loading"
      v-else
    >
      <div
        class="card_item"
        v-for="(good, index) in dataList"
        :key="index"
        @click="itemClick(good)"
      >
        <img
          :src="domain + good.cover"
          class="card_cover"
          alt=""
        />
        <div class="card_title">{{ good.goodsName }}</div>
        <div class="card_price">￥{{ good.goodsPrice }}</div>
      </div>
    </div>

    <div class="floor-pager">
      <el-pagination
        v-if="pager.total > 5"
        background
        layout="prev, pager, next"
        :total="pager.total"
        v-model:page-size="pager.pageSize"
        v-model:current-page="pager.current"
        @current-change="onPageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import { queryGoodsCategoryAll, queryFloorBaseService } from "@/api/home";
import useFloor from "@/hooks/useFloor";
const emit = defineEmits(["change", "detail"]);
const appStore = useAppStore();
const userStore = useUserStore();
const domain = computed(() => appStore.domain);
const village = computed(() => userStore.village);

const {
  loading,
  pager,
  dataList,
  categoryId,
  categoryList,
  categoryAllList,
  loadData,
  onPageChange,
  categoryChange,
} = useFloor({
  pageSize: 5,
  categoryApi: queryGoodsCategoryAll,
  dataApi: queryFloorBaseService,
  queryParams: {
    addressId: village.value.id || "",
  },
});

watch(
  village,
  (newVal) => {
    loadData({
      addressId: village.value.id || "",
    });
  },
  { deep: true }
);

const itemClick = (item) => {
  emit("detail", {
    item,
    type: "goods",
  });
};
</script>
<style lang="scss" scoped>
// 58  244 32(47)
.floor_block {
  // padding-top: 35px;
  .floor_head {
    width: 100%;
    height: 58px;
    &_title {
      font-size: 22px;
      color: #303133;
    }
    &_right {
      display: flex;
      align-items: center;
      height: 58px;

      .cate_list {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 30px;
        .cate_item {
          color: #424242;
          font-size: 16px;
          margin-left: 25px;
          height: 30px;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #e4393c;
          }
          &:first-child {
            margin-left: 0;
          }
          &.active {
            color: #e4393c;
            &::before {
              content: "";
              width: 100%;
              height: 2px;
              background-color: #e4393c;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
      .more_wrap {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          .more_txt {
            color: #e4393c;
          }
          .arrow_circle {
            background-color: #e4393c;
          }
        }
        .more_txt {
          color: #909399;
          font-size: 16px;
        }
        .arrow_circle {
          width: 20px;
          height: 20px;
          background-color: #b0b0b0;
          border-radius: 50%;
          margin-left: 8px;
        }
      }
    }
  }
  .floor_cont {
    height: 312.5px;
    display: flex;
    flex-flow: row wrap;
    .card_item {
      width: 230px;
      height: 300px;
      background-color: #fff;
      padding: 10px;
      box-sizing: border-box;
      margin-right: 12.5px;
      margin-bottom: 12.5px;
      cursor: pointer;
      &:nth-child(5n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
        transform: translate3d(0, -2px, 0);
        transition: all 0.2s linear;
      }
      .card_cover {
        width: 210px;
        height: 210px;
      }
      .card_title {
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 210px;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #303133;
      }
      .card_price {
        height: 30px;
        line-height: 30px;
        color: #e4393c;
        font-size: 16px;
      }
    }
    .empty_wrap {
      width: 100%;
      background-color: #fff;
      :deep(.el-empty) {
        padding: 10px 0;
      }
    }
  }
  .floor-pager {
    height: 47px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
