import { defineStore } from "pinia";
import { queryAddressByLocation } from "@/api/common";
const useUserStore = defineStore("user", {
  state: () => ({
    location: {
      latitude: 0,
      longitude: 0,
    },
    village: {
      city: "",
      area: "",
      street: "",
      community: "",
      id: "",
    },
    nearByList: [],
  }),

  getters: {},

  actions: {
    updateUserVillage(params) {
      console.log("params::", params);
      this.village = params;
    },
    async getUserVillage(params) {
      queryAddressByLocation(params).then((res) => {
        const obj = (res.data && res.data[0]) || {
          city: "",
          area: "",
          street: "",
          community: "",
          id: "",
        };
        if (res.status === 0) {
          this.nearByList = res.data;
          this.location = params;
          this.village = obj;
        }
      });
    },
  },
});

export default useUserStore;
