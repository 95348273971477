import request from "@/utils/request";

//获取所有
export function queryRegionAll(data) {
  return request({
    url: "/base/address/allCities",
    method: "get",
    params: data,
  });
}

// 根据经纬度查询用户地址
export function queryAddressByLocation(data) {
  return request({
    url: "/base/address/nearby",
    method: "get",
    params: data,
  });
}

// 查询系统文章
export function querySysArticle(data) {
  return request({
    url: "/base/system/article/one",
    method: "get",
    params: data,
  });
}

// 查询小区
export function queryVillageByCode(data) {
  return request({
    url: "/base/address",
    method: "get",
    params: data,
  });
}

// 查询省
export function queryProvince(data) {
  return request({
    url: "/base/address/province",
    method: "get",
    params: data,
  });
}

// 根据省查询市
export function queryCityByProvince(data) {
  return request({
    url: "/base/address/city",
    method: "get",
    params: data,
  });
}
