import { ref, onMounted } from "vue";

const useFloor = ({ pageSize, queryParams = {}, categoryApi, dataApi }) => {
  const pager = ref({
    current: 1,
    pageSize: pageSize,
    total: 0,
  });
  const loading = ref(false);
  const dataList = ref([]);
  const categoryId = ref(0);
  const categoryList = ref([]);
  const categoryAllList = ref([]);

  const categoryChange = (id) => {
    if (categoryId.value !== id) {
      categoryId.value = id;
      pager.value.current = 1;
      pager.value.total = 0;
      loadData();
    }
  };

  const onPageChange = () => {
    loadData();
  };

  const loadCategory = () => {
    categoryApi &&
      categoryApi().then((res) => {
        if (res.status === 0) {
          const cateList = res.data || [];
          categoryAllList.value = cateList;
          categoryList.value =
            cateList.length <= 5 ? cateList : cateList.slice(0, 5);
        }
      });
  };
  const loadData = (otherParams = {}) => {
    if (loading.value) return;
    loading.value = true;
    let defaultParams = {
      ...queryParams,
      ...otherParams,
      current: pager.value.current,
      pageSize: pager.value.pageSize,
    };
    if (categoryId.value) {
      defaultParams.categoryId = categoryId.value;
    }
    dataApi &&
      dataApi(defaultParams)
        .then((res) => {
          if (res.status === 0) {
            dataList.value = res.data || [];
            pager.value.total = (res.pagination && res.pagination.total) || 0;
          }
        })
        .finally(() => {
          loading.value = false;
        });
  };
  onMounted(() => {
    loadCategory();
    loadData();
  });
  return {
    loading,
    pager,
    dataList,
    categoryId,
    categoryList,
    categoryAllList,
    loadData,
    onPageChange,
    categoryChange,
  };
};

export default useFloor;
