<template>
  <div class="page bg">
    <top-bar></top-bar>
    <page-head></page-head>

    <div class="floor_wrap w1200">
      <div
        v-for="(floor, floorIdx) in floorData"
        :key="floorIdx"
        :id="`floor${floorIdx}`"
        :ref="`floor${floorIdx}`"
      >
        <area-goods
          v-if="floor.title === '本区商品'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail"
        />
        <area-service
          v-if="floor.title === '本区服务'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail"
        />
        <source-company
          v-if="floor.title === '源头企业'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail($event, floorIdx)"
        />
        <kite-article
          v-if="floor.title === '风筝专项'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail($event, floorIdx)"
        />
        <recommend
          v-if="floor.title === '为您推荐'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail($event, floorIdx)"
        />
        <video-article
          v-if="floor.title === '视频传播'"
          @change="getFloorTop(floorIdx)"
          @detail="onDetail($event, floorIdx)"
        />
      </div>
    </div>

    <div class="elevator_wrap">
      <div
        v-for="(item, index) in floorData"
        :key="index"
        class="elevator_item"
        :class="{ active: index === floorActive }"
        v-scroll-to="'#floor' + index"
        @click="floorActive = index"
      >
        {{ item.title }}
      </div>
      <div
        class="back_top_wrap"
        @click="backTop"
      >
        <el-icon><ArrowUpBold /></el-icon>
        <span class="back_top_name">置顶</span>
      </div>
    </div>

    <page-footer></page-footer>
  </div>
</template>

<script setup>
import TopBar from "@/components/TopBar/TopBar.vue";
import PageHead from "@/components/PageHead/PageHead.vue";
import { ref, onMounted, onUnmounted, getCurrentInstance, computed } from "vue";
import router from "@/router";
import AreaGoods from "./components/AreaGoods.vue";
import AreaService from "./components/AreaService.vue";
import SourceCompany from "./components/SourceCompany.vue";
import KiteArticle from "./components/KiteArticle.vue";
import Recommend from "./components/Recommend.vue";
import VideoArticle from "./components/VideoArticle.vue";
const floorActive = ref(0);
const floorData = ref([
  { title: "本区商品" },
  { title: "本区服务" },
  { title: "源头企业" },
  { title: "风筝专项" },
  { title: "为您推荐" },
  { title: "视频传播" },
]);
const floor0 = ref();
const floor1 = ref();
const floor2 = ref();
const floor3 = ref();
const floor4 = ref();
const floor5 = ref();

const onDetail = ($event, floorIdx) => {
  const { item, type } = $event;
  if (type === "goods") {
    router.push({
      path: "/product",
      query: {
        id: item.id,
      },
    });
  } else if (type === "article") {
    router.push({
      path: "/article",
      query: {
        id: item.id,
        floorIdx,
      },
    });
  }
};

const backTop = () => {
  floorActive.value = 0;
  window.scrollTo({ behavior: "smooth", top: 0 });
};
const topArr = ref([]);

const handleScroll = () => {
  if (!topArr.value.length) return;
  let scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  const idx = topArr.value.findIndex(
    (v) => scrollTop >= v - 400 && scrollTop <= v + 50
  );
  if (idx !== -1) {
    floorActive.value = idx;
  }
};

const getFloorTop = (idx) => {
  topArr.value = [
    floor0.value[0].offsetTop,
    floor1.value[0].offsetTop,
    floor2.value[0].offsetTop,
    floor3.value[0].offsetTop,
    floor4.value[0].offsetTop,
    floor5.value[0].offsetTop,
  ];
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  getFloorTop();
});

onUnmounted(() => {
  document.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss" scoped>
.page {
  // padding-top: 35px;
  box-sizing: border-box;

  .elevator_wrap {
    width: 80px;
    height: auto;
    position: fixed;
    top: 50vh;
    right: 16px;
    transform: translate(0, -50%);
    background-color: #fff;
    z-index: 100;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 6px;
    .elevator_item {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.09);
      height: 70px;
      line-height: 70px;
      font-size: 14px;
      color: #303133;
      cursor: pointer;
      text-align: center;
      &:hover {
        color: #e4393c;
      }
      &.active {
        color: #e4393c;
      }
    }
    .back_top_wrap {
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: #e4393c;
        .back_top_name {
          color: #e4393c;
        }
      }

      .back_top_name {
        font-size: 14px;
        color: #303133;
      }
    }
  }
}
</style>
