import axios from "axios";
import { ElNotification } from "element-plus";

// 创建axios实例
const service = axios.create({
  // baseURL: "https://www.wmkjfw.cn",
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // console.log("response-res::", res);
    return res;
  },
  (error) => {
    const errStr = "err" + error;
    if (errStr && errStr.indexOf("timeout") > -1) {
      //超时处理
      ElNotification({
        title: "提示",
        message: "请求超时，请重试",
        type: "error",
      });
    } else {
      ElNotification({
        title: "提示",
        message: error.message || "请求失败，请稍后再试",
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default service;
