<template>
  <el-dialog
    v-model="dialogVisible"
    title="选择小区"
    width="630px"
    destroy-on-close
    draggable
  >
    <div class="search_wrap">
      <el-input
        v-model="keyword"
        clearable
        placeholder="输入小区名称搜索"
        :prefix-icon="Search"
      />
      <el-button
        type="primary"
        @click="onSearch"
        >搜索</el-button
      >
    </div>

    <el-space>
      <el-select
        v-model="pCode"
        placeholder="选择省"
      >
        <el-option
          v-for="item in provinceAll"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="cCode"
        placeholder="选择市"
        :loading="cityLoading"
      >
        <el-option
          v-for="item in cityList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="aCode"
        placeholder="选择区/县"
        @change="onAreaChange"
        :loading="areaLoading"
      >
        <el-option
          v-for="item in areaList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-space>

    <div
      class="list_wrap"
      v-loading="listLoading"
    >
      <div v-if="villageList.length">
        <div
          class="list_item"
          v-for="(item, index) in villageList"
          :key="index"
          @click="onVillageClick(item)"
        >
          {{ `${item.province}${item.city}${item.area}${item.community}` }}
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无" />
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import useAppStore from "@/store/modules/app";
import useUserStore from "@/store/modules/user";
import { queryVillageByCode } from "@/api/common";
const appStore = useAppStore();
const userStore = useUserStore();
const emit = defineEmits(["select"]);
const provinceAll = computed(() => appStore.provinceAll || []);
const cityListMap = computed(() => appStore.cityListMap || []);
const cityList = computed(() => {
  if (!pCode.value) return [];
  return cityListMap.value[pCode.value] || [];
});
const areaList = computed(() => appStore.areaList || []);

const dialogVisible = ref(false);

const show = () => {
  dialogVisible.value = true;
};

const onSubmit = () => {};

const keyword = ref("");
const pCode = ref("");
const cCode = ref("");
const aCode = ref("");
const cityLoading = ref(false);
const areaLoading = ref(false);
const listLoading = ref(false);
watch(
  () => pCode.value,
  (newVal) => {
    cCode.value = "";
    if (!cityList.value.length) {
      cityLoading.value = true;
      appStore.getCityAll(newVal).finally(() => {
        cityLoading.value = false;
      });
    }
  }
);

watch(
  () => cCode.value,
  (newVal) => {
    aCode.value = "";
    areaLoading.value = true;
    appStore.getAreaAll(newVal).finally(() => {
      areaLoading.value = false;
    });
  }
);

const villageList = ref([]);
const onAreaChange = (val) => {
  listLoading.value = true;
  let params = {};
  if (val) {
    params.areaCode = val;
  }
  if (keyword.value) {
    params.community = keyword.value;
  }
  queryVillageByCode(params)
    .then((res) => {
      if (res.status === 0) {
        villageList.value = res.data || [];
      }
    })
    .finally(() => {
      listLoading.value = false;
    });
};

const onSearch = () => {
  if (!keyword.value && !aCode.value) {
    ElMessage({
      message: "请输入小区名称或者选择省市区",
      type: "error",
    });
    return;
  }
  onAreaChange(aCode.value);
};

const onVillageClick = (item) => {
  userStore.getUserVillage({
    longitude: item.longitude,
    latitude: item.latitude,
  });
  dialogVisible.value = false;
};

const getRegionData = () => {
  appStore.queryRegionData();
};

onMounted(() => {
  getRegionData();
});

defineExpose({
  show,
});
</script>

<style lang="scss" scoped>
.search_wrap {
  width: 580px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.list_wrap {
  min-height: 280px;
  width: 100%;
  padding-top: 20px;
  .list_item {
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    color: #333;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    cursor: pointer;
    &:hover {
      color: #e4393c;
    }
  }
}
</style>
