import { defineStore } from "pinia";
import { queryCityByProvince, queryRegionAll } from "@/api/common";
const useAppStore = defineStore("app", {
  state: () => ({
    domain: process.env.VUE_APP_REQUEST_API,
    cityAll: [],
    provinceAll: [],
    cityCodeList: [],
    cityListMap: {},
    areaListMap: {},
    areaList: [],
  }),

  getters: {},

  actions: {
    queryRegionData() {
      queryRegionAll().then((res) => {
        if (res.status === 0) {
          const province = res.data.province;
          const city = res.data.city;
          const codes = Object.keys(province);
          let provinceData = codes.map((code) => ({
            label: province[code],
            value: code.substring(0, 2),
          }));
          this.provinceAll = provinceData;
          this.cityCodeList = city;
        }
      });
    },
    getCityAll(pCode) {
      return new Promise((resolve, reject) => {
        queryCityByProvince({
          pcode: pCode,
        })
          .then((res) => {
            if (res.status === 0) {
              let dataList = (res.data || []).map((v) => ({
                label: v.name,
                value: v.code,
              }));
              let obj = {
                ...this.cityListMap,
              };
              obj[pCode] = dataList;
              this.cityListMap = {
                ...obj,
              };
              resolve();
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject();
          });
      });
    },
    getAreaAll(cCode) {
      return new Promise((resolve, reject) => {
        let areaCodeList = this.cityCodeList[cCode + "00"];
        let areaData = [];
        if (areaCodeList) {
          let keys = Object.keys(areaCodeList);
          areaData = keys.map((v) => ({
            label: areaCodeList[v]["name"],
            value: v,
          }));
          this.areaList = areaData;
        }
        resolve();
      });
    },
  },
});

export default useAppStore;
