import { createApp } from "vue";
import App from "./App.vue";
import dayjs from "dayjs";
import "./styles/index.scss";
import "./styles/element/index.scss";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import PageFooter from "./components/PageFooter/PageFooter.vue";
import VueScrollTo from "vue-scrollto";

const app = createApp(App);

app.use(ElementPlus);
app.use(VueScrollTo);
app.component("PageFooter", PageFooter);

// 全局异常处理
app.config.errorHandler = (err, vm, info) => {
  if (err instanceof Error) {
    console.error("❌ 提示:", err);
  }
};
// 全局过滤器
app.config.globalProperties.$filters = {
  timeFormat(value) {
    if (value) {
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return dayjs().format("YYYY-MM-DD HH:mm:ss");
    }
  },
};
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(router).use(store).mount("#app");
