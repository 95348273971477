<template>
  <div class="head_wrap w1200">
    <div class="logo_wrap">
      <a
        href="/"
        class="logo_link"
      >
        <img
          src="https://www.wmkjfw.cn/assets/images/logo.png"
          class="logo_img"
          alt=""
        />
      </a>
    </div>
    <div class="search_cent">
      <div class="locat_wrap search_block">
        <div class="address_wrap">
          <input
            type="text"
            disabled
            v-model="locatName"
            class="search_ipt"
          />
          <el-button
            color="#e4393c"
            size="small"
            @click="selectAddressClick"
            >选择小区</el-button
          >
        </div>

        <el-dropdown
          v-if="nearByList.length"
          @command="nearChange"
        >
          <div class="search_btn center">
            <span class="btn_name">社区定位</span>
            <el-icon size="14px"><Position /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(near, index) in nearByList"
                :key="near.id"
                :command="near.id"
                >{{ near.community }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div
          class="search_btn center"
          v-else
        >
          <span class="btn_name">社区定位</span>
          <el-icon size="14px"><Position /></el-icon>
        </div>
      </div>
      <div class="goods_wrap search_block">
        <el-popover
          placement="bottom-start"
          trigger="click"
        >
          <template #reference>
            <input
              type="text"
              v-model="searchGoodsName"
              placeholder="服务商品"
              class="search_ipt"
            />
          </template>
          <div>
            <div
              v-for="keyItem in keywordList"
              :key="keyItem.id"
              class="key_cell"
              @click="searchGoodsName = keyItem.name"
            >
              {{ keyItem.name }}
            </div>
          </div>
        </el-popover>

        <div
          class="search_btn center"
          @click="onSearchProduct"
        >
          <span class="btn_name">服务商品</span>
          <el-icon size="14px"><Search /></el-icon>
        </div>
      </div>
      <div class="locat_wrap search_block">
        <input
          type="text"
          v-model="storeName"
          class="search_ipt"
          placeholder="网点搜索"
        />
        <div
          class="search_btn center"
          @click="onSearchStore"
        >
          <span class="btn_name">网点搜索</span>
          <el-icon size="14px"><Aim /></el-icon>
        </div>
      </div>
      <div class="locat_wrap search_block">
        <input
          type="text"
          v-model="companyName"
          class="search_ipt"
          placeholder="源头企业"
        />
        <div
          class="search_btn center"
          @click="onSearchCompany"
        >
          <span class="btn_name">源头企业</span>
          <el-icon size="14px"><Discount /></el-icon>
        </div>
      </div>
    </div>
    <div class="down_wrap">
      <el-popover
        placement="bottom-end"
        :width="270"
        trigger="hover"
      >
        <template #reference>
          <el-card shadow="never">
            <div class="">
              <img
                class="down_qrcode"
                src="https://www.wmkjfw.cn/assets/images/downloadCode.png"
                alt=""
              />
              <div class="down_title">APP下载</div>
            </div>
          </el-card>
        </template>
        <img
          class="down_qrcode_big"
          src="https://www.wmkjfw.cn/assets/images/downloadCode.png"
          alt=""
          style="width: 250px; height: 250px"
        />
      </el-popover>
    </div>

    <select-address-modal ref="selectAddressRef" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import useUserStore from "@/store/modules/user";
import router from "@/router";
import { useRoute } from "vue-router";
import SelectAddressModal from "./components/SelectAddressModal.vue";
import { queryKeywordAll, queryNetStoreAll } from "@/api/home";

const userStore = useUserStore();
const route = useRoute();
const emit = defineEmits(["searchGoods"]);
const locatName = computed(
  () =>
    `${userStore.village.city}${userStore.village.area}${userStore.village.street}${userStore.village.community}` ||
    "获取位置中"
);
const nearByList = computed(() => userStore.nearByList || []);
const userLocation = computed(() => userStore.location || {});
const searchGoodsName = ref("");
const companyName = ref("");
const storeName = ref("");

const getCurrentLocation = () => {
  var geolocation = new BMapGL.Geolocation();
  geolocation.getCurrentPosition(function (r) {
    if (r && r.point) {
      const { lng, lat } = r.point;
      // 获取到定位 查询小区
      userStore.getUserVillage({
        longitude: lng,
        latitude: lat,
      });
    } else {
      console.log("获取定位信息失败");
    }
  });
};

const nearChange = (id) => {
  const row = nearByList.value.find((v) => v.id === id);
  if (row) {
    userStore.updateUserVillage(row);
  }
};

const selectAddressRef = ref();
const selectAddressClick = () => {
  selectAddressRef.value.show();
};

const setKeyword = (word) => {
  searchGoodsName.value = word;
};

const onSearchProduct = () => {
  if (route.path !== "/plist") {
    router.push({
      path: "/plist",
      query: {
        keyword: searchGoodsName.value,
      },
    });
  } else {
    emit("searchGoods", searchGoodsName.value);
  }
};

const setCompany = (word) => {
  companyName.value = word;
};

const onSearchCompany = () => {
  if (route.path !== "/clist") {
    router.push({
      path: "/clist",
      query: {
        keyword: companyName.value,
      },
    });
  } else {
    emit("searchCompany", companyName.value);
  }
};

const setStore = (word) => {
  storeName.value = word;
};

const onSearchStore = () => {
  if (route.path !== "/store") {
    router.push({
      path: "/store",
      query: {
        keyword: storeName.value,
      },
    });
  } else {
    emit("searchStore", storeName.value);
  }
};

const keywordList = ref([]);
const getKeyWord = () => {
  queryKeywordAll().then((res) => {
    if (res.status === 0) {
      keywordList.value = res.data || [];
    }
  });
};

onMounted(() => {
  if (!userLocation.value.latitude || !userLocation.value.longitude) {
    getCurrentLocation();
  }
  getKeyWord();
});

defineExpose({
  setKeyword,
  setCompany,
  setStore,
});
</script>

<style lang="scss" scoped>
:deep(.key_cell) {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  border-bottom: 0.5px solid #eee;
  &:hover {
    color: #e4393c;
  }
}
.head_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .logo_wrap {
    .logo_link {
      display: inline-block;
    }
    .logo_img {
      width: 108px;
      height: 108px;
      cursor: pointer;
    }
  }
  .search_cent {
    width: 700px;
    padding-top: 10px;
    .search_block {
      width: 100%;
      display: flex;
      align-items: center;
      border: 2px solid #e4393c;
      font-size: 12px;
      height: 34px;
      background-color: #fff;
      margin-bottom: 10px;
    }
    .search_ipt {
      border: 0;
      outline: none;
      background-color: #fff;
      color: #303133;
      font-size: 14px;
      padding: 0 10px;
      box-sizing: border-box;
      flex: 1;
    }
    .search_btn {
      width: 100px;
      height: 30px;
      background-color: #e4393c;
      color: #fff;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      cursor: pointer;
      .btn_name {
        margin-right: 3px;
      }
    }
  }
  .down_wrap {
    :deep(.el-card__body) {
      padding: 10px;
      box-sizing: border-box;
    }
    .down_qrcode {
      width: 100px;
      height: 100px;
    }
    .down_title {
      text-align: center;
    }
  }
  .address_wrap {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 10px;
    box-sizing: border-box;
  }
}
</style>
