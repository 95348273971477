import request from "@/utils/request";

/**
 *
 * ---------------------------首页部分--------------------------
 */

//获取所有网点
export function queryNetStoreAll(data) {
  return request({
    url: "/base/user/store",
    method: "get",
    params: data,
  });
}

//获取所有关键词
export function queryKeywordAll(data) {
  return request({
    url: "/base/goods/keyWord",
    method: "get",
    params: data,
  });
}

//获取所有商品分类
export function queryGoodsCategoryAll(data) {
  return request({
    url: "/base/goods/category",
    method: "get",
    params: data,
  });
}

// 查询商品列表
export function queryBaseGoods(data) {
  return request({
    url: "/base/goods",
    method: "get",
    params: data,
  });
}

// 检索热销商品（楼层使用）
export function queryFloorBaseGoods(data) {
  return request({
    url: "/base/goods/queryHotAreaGoods/pcmall",
    // url: "/base/goods/queryHotAreaGoods",
    method: "get",
    params: data,
  });
}

// 查询热销服务
export function queryHotService(data) {
  return request({
    url: "/base/goods/queryHotAreaService/pcmall",
    method: "get",
    params: data,
  });
}

// 检索热销服务（楼层使用）
// export function queryFloorBaseService(data) {
//   return request({
//     url: "/base/goods/queryHotAreaService",
//     method: "get",
//     params: data,
//   });
// }

// 检索热销服务（楼层使用）
export function queryFloorBaseService(data) {
  return request({
    url: "/base/goods/queryHotAreaService/pcmall",
    method: "get",
    params: data,
  });
}

// 查询源头企业分类
export function queryCompanyCategory(data) {
  return request({
    url: "/base/enterprise/category/list",
    method: "get",
    params: data,
  });
}

// 查询源头企业文章列表
export function queryCompanyArticle(data) {
  return request({
    url: "/base/enterprise/article",
    method: "get",
    params: data,
  });
}

// 查询源头企业文章详情
export function queryCompanyArticleDto(data) {
  return request({
    url: "/base/enterprise/article/one",
    method: "get",
    params: data,
  });
}

// 查询风筝专项分类
export function queryKiteCategory(data) {
  return request({
    url: "/base/sport/kiteCategory/list",
    method: "get",
    params: data,
  });
}

// 查询风筝专项文章列表
export function queryKiteArticle(data) {
  return request({
    url: "/base/sport/kite",
    method: "get",
    params: data,
  });
}

// 查询风筝专项文章详情
export function queryKiteArticleDto(data) {
  return request({
    url: "/base/sport/kite/one",
    method: "get",
    params: data,
  });
}

// 查询为您推荐分类
export function queryRecommendCategory(data) {
  return request({
    url: "/base/media/recommendCategory/list",
    method: "get",
    params: data,
  });
}

// 查询为您推荐文章
export function queryRecommendArticle(data) {
  return request({
    url: "/base/media/recommend",
    method: "get",
    params: data,
  });
}

// 查询为您推荐文章详情
export function queryRecommendArticleDto(data) {
  return request({
    url: "/base/media/recommend/one",
    method: "get",
    params: data,
  });
}

// 查询视频传播分类
export function queryVideoCategory(data) {
  return request({
    url: "/base/media/newsCategory/list",
    method: "get",
    params: data,
  });
}

// 查询视频传播文章
export function queryVideoArticle(data) {
  return request({
    url: "/base/media/news",
    method: "get",
    params: data,
  });
}

// 查询视频传播文章详情
export function queryVideoArticleDto(data) {
  return request({
    url: "/base/media/news/one",
    method: "get",
    params: data,
  });
}
